import React from 'react'
import Contact from '../../components/Contact/contact'
import LayoutBs from '../../components/LayoutBs'
import ContactHeader from '../../components/Header/contact_header'
import "animate.css/animate.min.css";
import Helmet from 'react-helmet'
import AOS from 'aos';

const prefix_string = "bs";
class ContactPageBs extends React.Component{
    componentDidMount(){
        AOS.init({
          duration : 500
        })
      }
    render(){
        return(
  <LayoutBs>
      <Helmet
          title="WELL – KONTAKT | Agencija za digitalni marketing u Sarajevu (SCC)"
          meta={[
            { name: 'description', content: 'WELL - Vaša agencija za digitalni marketing u srcu Sarajeva (Sarajevo City Centar). Radujemo se Vašem upitu! Tel.: +387 33 831 428' },
            {name: 'og:image', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg"},
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
         ]} >
        </Helmet>

    <div data-aos='fade-zoom-in'>
        <ContactHeader  prefix_string_page={prefix_string}/>
        <Contact prefix_string_page={prefix_string}/>
    </div>
</LayoutBs>
)
        }
    }
export default ContactPageBs
